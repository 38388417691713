.arrow {
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100px;
  
}

.ion-icon {
    height: 50px !important;
    width: 50px !important;
    color: white !important;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}