.segment5-background {
    background: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1605801520/Photos/crmo74r0d4gf2miuprqc.jpg"); 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.segment5-text-container {
    width: 75%;
    padding-top: 75px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background-color: rgba(68,72,78,0.75);
    box-shadow: 0px 0px 80px -40px #000011;
    font-size: 45px;
    color: rgba(255,255,255,0.95);
    text-align: center;
    padding: 25px;
}

.segment5-title-text {
    font-size: 80px !important;
    border: 4px solid rgba(255,255,255,0.95);
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px;
    margin-bottom: 20px;
}

/* Changes styling for mobile devices */
@media screen and (max-width: 900px) {
    .segment5-background {
        background: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1605801520/Photos/crmo74r0d4gf2miuprqc.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .segment5-text-container {
        width: 75%;
        font-size: 23px;
        padding: 10px !important
    }
    
    .segment5-title-text {
        font-size: 30px !important;
        border: 4px solid rgba(255,255,255,0.95);
        padding: 10px !important;
        margin: 20px !important;
    }
}
