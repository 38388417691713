.segment2-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Only applies to desktops */
@media screen and (min-width: 900px) {
    .segment2-background {
        background: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1605801448/Photos/jaotaedkjfpqb7agkhuk.jpg");
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100%;
    }

    .segment2-text {
        font-size: 50px;
        color: rgba(255,255,255,0.9);
        text-align: right;
        margin-right: 10px;
    }
}

/* Changes styling for mobile devices */
@media screen and (max-width: 900px) {
    .segment2-background {
        background: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1606181638/Photos/ltve1o0jxq0aqg5zdbpc.jpg"); 
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100%;
    }

    .segment2-text {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 30px;
        color: rgba(255,255,255,0.9);
        text-align: center;
    }

    .mobile-remove {
        display: none;
    }
}