.landingpage-background {
    background: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1605801505/Photos/z8b9v8i0ihp2clowfdbk.jpg"); 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
}

.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    font-family: 'Kaushan Script', cursive;
    text-shadow: 2px 2px 4px #000000;
    color: white;
    font-size: 120px;
}

.subtitle {
    font-family: 'Kaushan Script', cursive;
    text-shadow: 2px 2px 4px #000000;
    color: white;
    font-size: 35px;
    margin-top: 10px;
}

.hello {
    background: rgba(68,72,78,0.85) !important;
    border-color: rgba(68,72,78,0.85) !important;
    font-size: 25px !important;
    color: #ffffff;
    border-radius: 5px;
    
    width: 115px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px !important;
    line-height: 1;
    margin-top: 30px;
}

.purchase-button-landing:hover {
    background-color: rgba(68,72,78,0.5);
    border-color: rgba(68,72,78,0.5);
}

/* Changes styling for mobile devices */
@media screen and (max-width: 900px) {
    .landingpage-background {
        background: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1605801448/Photos/njpk1p24orasq6wwcuc9.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .title {
        font-family: 'Kaushan Script', cursive;
        text-shadow: 1px 1px 4px #000000;
        color: white;
        font-size: 60px;
        text-align: center;
    }
    
    .subtitle {
        font-family: 'Kaushan Script', cursive;
        text-shadow: 1px 1px 4px #000000;
        color: white;
        font-size: 15px;
        margin-top: 10px;
        text-align: center;
    }
}

/* Below title and subtitle have same font as rest of project
   and have backgrounds */

/* .title {
    box-shadow: 0px 0px 80px -20px #000011;
    background-color: rgba(68,72,78,1);
    padding-left: 25px;
    padding-right: 25px;
    color: white;
    font-size: 175px;
    padding-top: 20px;
    line-height: 1;
}

.subtitle {
    box-shadow: 0px 0px 80px -20px #000011;
    background-color: rgba(68,72,78,1);
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    font-size: 50px;
    padding-top: 5px;
    line-height: 1;
    margin-top: 10px;
} */