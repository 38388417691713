.final-background {
    background-color: #ffffff;
    height: 100%;
}

.final-left-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.book-cover {
    background-image: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1605801314/Photos/ctfrhluynbcvonfyfxdu.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 70%;
    width: 70%;
}

.final-right-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    font-size: 35px;
    color: rgba(26,19,33,1);
    text-align: center;
    padding: 100px;
    padding-right: 200px;
}

.top-spacer {
    font-size: 55px !important;
    margin-top: 30px;
}

.purchase-button {
    background: rgba(68,72,78,0.85);
    border-color: rgba(68,72,78,0.85);
    font-size: 50px;
    color: #ffffff;
    border-radius: 5px;
    
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    line-height: 1;
    margin-top: 30px;
}

.purchase-button:hover {
    background-color: rgba(68,72,78,0.5);
    border-color: rgba(68,72,78,0.5);
}

/* Changes styling for mobile devices */
@media screen and (max-width: 900px) {
    .final-right-container {
        font-size: 40px;
        padding: 0;
    }

    .purchase-button {
        font-size: 40px;
        border-radius: 2px;
        
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 9px;
        line-height: 1;
        margin-top: 30px;
    }

    .final-left-container {
        display: none;
    }

    .purchase-button:active {
        background-color: rgba(68,72,78,0.5);
        border-color: rgba(68,72,78,0.5);
    }
}
