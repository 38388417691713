.transition-background {
    height: 10%;
    background: radial-gradient(circle, rgba(36,39,43,1) 0%, rgba(26,19,33,1) 100%);;
    border-top: solid #24272B 5px;
    border-bottom: solid #24272B 5px;
    z-index: 5000;

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    line-height: 1;
    padding-top: 5px;
    color: rgba(235,235,235,0.5);
}

/* Changes styling for mobile devices */
@media screen and (max-width: 900px) {
    .transition-background {
        font-size: 18px;
        border-top: solid #24272B 2px;
        border-bottom: solid #24272B 2spx;
    }
}