.segment4-background {
    background: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1605801506/Photos/aayokmed81wqlgy9g5rt.jpg"); 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
}

.segment4-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.segment4-text {
    background-color: rgba(68,72,78,0.75);
    box-shadow: 0px 0px 80px -40px #000011;
    font-size: 50px;
    color: rgba(255,255,255,0.99);
    text-align: center;
    width: 75%;
    padding: 40px;
}

/* Changes styling for mobile devices */
@media screen and (max-width: 900px) {
    .segment4-background {
        background: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1605801398/Photos/ps1j88rml4vezoduolgi.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100%;
    }
    
    .segment4-text-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .segment4-text {
        background-color: rgba(68,72,78,0.75);
        box-shadow: 0px 0px 80px -40px #000011;
        font-size: 30px;
        color: rgba(255,255,255,0.99);
        text-align: center;
        width: 75%;
        padding: 40px;
    }
}