.segment3-background {
    background: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1605801395/Photos/g1ufosno1oibnphipnkg.jpg"); 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
    border-bottom: solid #24272B 1px;
}

.segment3-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.segment3-text {
    background-color: rgba(68,72,78,0.75);
    box-shadow: 0px 0px 80px -40px #000011;
    font-size: 50px;
    color: rgba(255,255,255,0.99);
    text-align: center;
    width: 40%;
    line-height: 1;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.segment3-list {
    list-style-position: inside;
    list-style-type: none;
}

/* Changes styling for mobile devices */
@media screen and (max-width: 900px) {
    .segment3-background {
        background: url("https://res.cloudinary.com/nanjungilgi/image/upload/v1605801395/Photos/g1ufosno1oibnphipnkg.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 100%;
        border-bottom: solid #24272B 1px;
    }

    .segment3-text {
        background-color: rgba(68,72,78,0.75);
        box-shadow: 0px 0px 80px -40px #000011;
        font-size: 30px;
        color: rgba(255,255,255,0.99);
        text-align: center;
        width: 75%;
        line-height: 1;
        padding-top: 20px;
    }
}